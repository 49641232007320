import React, { useEffect, useState } from 'react';
import GlobalStyles from './../styles/global-styles';
import SiteHeader from '../components/common/header';
import SiteFooter from '../components/common/footer';
import loadable from '@loadable/component';

import { AppContextProvider } from '../components/common/context';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import { hasGdprCookie, hasMessage } from '../utils/cookie-utils';

const CookieBanner = loadable(() => import('../components/common/cookie'));
const EntryMessage = loadable(() =>
  import('../components/common/entry-message')
);

const duration = 0.5;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

const Layout = ({ children, location }) => {
  const url = (location && location.pathname) || '';
  const { strapi } = useStaticQuery(graphql`
    {
      strapi {
        indicators(publicationState: LIVE) {
          name
          slug
          icon
          summary
        }
        map {
          slug
          name
        }
        entryMessage(publicationState: LIVE) {
          uid
          title
          text
          subtitle
          updated_at
          actions {
            url
            label
            is_external
            id
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5, width: 640)
              }
            }
            url
            alternativeText
          }
        }
      }
    }
  `);
  const state = {
    ...strapi,
  };
  const [showEntry, setShowEntry] = useState(false);
  useEffect(() => {
    if (strapi.entryMessage?.uid && !hasMessage(strapi.entryMessage.uid)) {
      const til = setTimeout(() => {
        setShowEntry(true);
      }, 500);
      return () => {
        clearTimeout(til);
      };
    }
  });
  return (
    <div>
      <AppContextProvider value={state}>
        <GlobalStyles />
        <SiteHeader />
        <motion.main
          key={url}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {children}
        </motion.main>
        <SiteFooter />
        {!hasGdprCookie() && <CookieBanner />}
        {showEntry && <EntryMessage {...strapi.entryMessage} />}
      </AppContextProvider>
    </div>
  );
};

export default Layout;
