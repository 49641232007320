import React from 'react';
import PoliticalLeadership from './political_leadership';
import HealthcareSystems from './healthcare_systems';
import TreatmentAccess from './treatment_access';
import AccessPathways from './access_pathways';
import Diagnosis from './diagnosis';
import About from './about';
import Project from './project';
import Resources from './resources';
import Partners from './partners';

const getComponent = (name) => {
  switch (name) {
    case 'leadership':
      return PoliticalLeadership;
    case 'healthcare_systems':
      return HealthcareSystems;
    case 'diagnosis':
      return Diagnosis;
    case 'access_pathways':
      return AccessPathways;
    case 'treatment_access':
      return TreatmentAccess;
    case 'about':
      return About;
    case 'project':
      return Project;
    case 'resources':
      return Resources;
    case 'partners':
      return Partners;
    default:
      return null;
  }
};
const Icon = ({ name, ...rest }) => {
  const Cmp = getComponent(name);
  if (Cmp) return <Cmp {...rest} />;
  return '';
};

export default Icon;
