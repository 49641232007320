import React from 'react';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import { childVariants, parentVariants } from './variants';

const DEFAULT_FILL = {
  foreground: '#00A79D',
  background: '#564879',
};

const Logo = ({ className, fills, useInView = true }) => {
  const { foreground, background } = {
    ...DEFAULT_FILL,
    ...fills,
  };
  return (
    <InView threshold={0.5} triggerOnce={true}>
      {({ ref, inView }) => (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 680 680"
          ref={ref}
          variants={parentVariants}
          className={className}
          {...(useInView
            ? {
                initial: 'out',
                animate: inView ? 'in' : 'out',
              }
            : {})}
        >
          <motion.g variants={childVariants}>
            <path
              fill={foreground}
              d="M71.8199995 58h19.09v20.46h-19.09V58zm0 336.609509V383.09h19.09v11.405432c-2.9960875-.580861-6.0908514-.885214-9.2566678-.885432-3.3693121-.000232-6.6581912.344037-9.8333322.999509zm19.09-25.919509h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09V92.85h19.09v21.88z"
            />
            <path
              fill={background}
              d="M90.9099995 489.198512V621.22h-19.09V489.207414C49.6770691 484.773235 32.99676856 465.220285 33 441.773335c.00368153-26.712712 21.6606208-48.365174 48.3733322-48.363334 26.7127114.001841 48.3666673 21.657288 48.3666673 48.369999-.029021 23.434889-16.7003712 42.968306-38.83 47.418512zm-9.55-76.698512c-16.1708974 0-29.28 13.109103-29.28 29.28 0 16.170897 13.1091026 29.28 29.28 29.28 16.1708975 0 29.28-13.109103 29.28-29.28-.0165361-16.164043-13.1159569-29.263464-29.28-29.28z"
            />
          </motion.g>
          <motion.g variants={childVariants}>
            <path
              fill={foreground}
              d="M202.83 58h19.09v20.46h-19.09V58zm19.09 165.57h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09V92.85h19.09v21.88z"
            />
            <path
              fill={background}
              d="M221.92 321.598507V621.22h-19.09V321.607426c-22.1401432-4.433639-38.82-23.982638-38.82-47.427426 0-26.714013 21.6559867-48.37 48.37-48.37 26.7140133 0 48.37 21.655987 48.37 48.37-.029015 23.434462-16.6999351 42.968217-38.83 47.418507zm-9.54-76.698509c-11.8435637-.004043-22.5231829 7.127235-27.0583215 18.0681-4.5351387 10.940866-2.0325351 23.536355 6.3406991 31.91245s20.9678685 10.883001 31.9102824 6.3516C234.5150739 296.700747 241.65 286.023564 241.65 274.18c-.0165255-16.160144-13.1098623-29.257954-29.27-29.280002z"
            />
          </motion.g>
          <motion.g variants={childVariants}>
            <path
              fill={foreground}
              d="M331.77 292.61986V274.25h19.09v18.23576c-2.9815704-.574775-6.0606357-.87576-9.21-.87576-3.3858509 0-6.6904489.347885-9.88 1.00986zM331.77 58h19.09v20.46h-19.09V58zm19.09 201.85h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09V92.85h19.09v21.88z"
            />
            <path
              fill={background}
              d="M350.86 387.110445V621.23h-19.09V387.135418c-22.1600501-4.41809-38.86-23.976513-38.86-47.435418 0-26.714013 21.6559867-48.37 48.37-48.37 26.7140133 0 48.37 21.655987 48.37 48.37-.0289976 23.42034-16.6798497 42.944674-38.79 47.410445zM341.28 310.42c-16.1708975 0-29.28 13.109103-29.28 29.28 0 16.170897 13.1091025 29.28 29.28 29.28 16.1708975 0 29.28-13.109103 29.28-29.28-.0165361-16.164043-13.115957-29.263464-29.28-29.28z"
            />
          </motion.g>
          <motion.g variants={childVariants}>
            <path
              fill={foreground}
              d="M460.71 176.632353V165.41h19.1v11.066149c-2.9659896-.568518-6.0282371-.866149-9.16-.866149-3.4070866 0-6.7318979.352262-9.94 1.022353zM479.81 151.01h-19.1v-21.88h19.1v21.88zm0-36.28h-19.1V92.85h19.1v21.88zM460.71 58h19.09v20.46h-19.09V58z"
            />
            <path
              fill={background}
              d="M479.8 271.252524V621.22h-19.09V271.253416c-22.1301875-4.441403-38.8-23.985688-38.8-47.423416 0-26.714013 21.6559867-48.37 48.37-48.37 26.7140133 0 48.37 21.655987 48.37 48.37-.0290238 23.441521-16.7099801 42.979985-38.85 47.422524zM470.28 194.55c-11.8426545 0-22.519205 7.133843-27.0511927 18.075029-4.5319877 10.941186-2.0269152 23.535036 6.3471061 31.909058 8.3740214 8.374021 20.9678714 10.879093 31.9090575 6.347106C492.426157 246.349205 499.56 235.672655 499.56 223.83c-.0220338-16.161762-13.118238-29.257966-29.28-29.28z"
            />
          </motion.g>
          <motion.g variants={childVariants}>
            <path
              fill={foreground}
              d="M608.7399999 332.41h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09v-21.88h19.09v21.88zm0-36.28h-19.09V92.85h19.09v21.88zm-19.09-56.73h19.09v20.46h-19.09V58z"
            />
            <path
              fill={background}
              d="M608.7399999 441.658507V621.23h-19.09V441.660076c-15.5920819-3.13787-28.89464213-13.836751-35.13805303-28.909678-7.48675698-18.074631-3.34842509-38.87943 10.48529793-52.713153 13.8337231-13.833723 34.6385224-17.972055 52.7131527-10.485298 18.0746302 7.486757 29.8596024 25.124214 29.8596024 44.688053-.0290151 23.434462-16.6999351 42.968217-38.83 47.418507zm-9.54-76.698507c-11.8435637-.004045-22.5231829 7.127233-27.0583216 18.068098-4.5351386 10.940866-2.0325351 23.536355 6.3406992 31.91245 8.3732342 8.376095 20.9678685 10.883001 31.9102823 6.3516 10.9424139-4.531401 18.0773401-15.208584 18.0773401-27.052148 0-16.171858-13.0981572-29.28793-29.27-29.31v.03z"
            />
          </motion.g>
        </motion.svg>
      )}
    </InView>
  );
};

export default Logo;
