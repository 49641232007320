import React from 'react';
import { Link } from 'gatsby';
import Sandbox from '../atoms/sandbox';
import Icon from '../svgs/icon';
import { motion } from 'framer-motion';
import ArrowRight from '../../images/icons/arrow_right.inline.svg';
import ArrowDropUp from '../../images/icons/arrow_drop_up.inline.svg';
import Portal from '../common/portal';
import '../../styles/menu-backdrop-gradient.css';
import tw, { css } from 'twin.macro';
import { useEffect } from 'react';
import { useRef } from 'react';
import {
  enableScrollLock,
  clearScollLock,
} from '../../utils/use-body-scroll-lock';
import Simplebar from 'simplebar-react';

const Submenu = ({ data, close }) => {
  const ref = useRef();
  useEffect(() => {
    enableScrollLock(ref.current ? ref.current.getScrollElement() : true);
    return () => clearScollLock();
  });
  return (
    <>
      <Portal>
        <motion.div
          tw="fixed top-16 left-0 right-0 bottom-0 z-20"
          className="bg-gradient-backdrop"
          variants={{
            in: { opacity: 1 },
            out: { opacity: 0 },
          }}
          onClick={close}
        ></motion.div>
      </Portal>
      <motion.div
        variants={{
          in: {
            y: 0,
            opacity: 1,
            transition: {
              staggerChildren: 0.2,
              when: 'beforeChildren',
            },
          },
          out: {
            y: -40,
            opacity: 0,
            transition: {
              when: 'afterChildren',
            },
          },
        }}
        tw="absolute w-full  bg-white z-50 text-center top-12 xl:top-16"
      >
        <Simplebar
          autoHide={true}
          ref={ref}
          css={[
            tw`max-h-full overflow-y-auto overflow-x-hidden`,
            css`
              max-height: calc(85vh - 84px);
              .simplebar-scrollbar:before {
                ${tw`bg-brand-green`}
              }
            `,
          ]}
        >
          <div tw="py-4 px-6 md:(pt-12 pb-8 px-16) xl:pb-16">
            <motion.h3
              tw="font-bold text-brand-green text-2xl md:(text-3xl) lg:text-4xl"
              variants={{
                in: { y: 0, opacity: 1 },
                out: { y: -20, opacity: 0 },
              }}
            >
              {data.title}
            </motion.h3>
            <motion.div
              tw="text-sm lg:(text-base mt-4) max-w-screen-lg mx-auto lg:px-24 xl:text-lg"
              variants={{
                in: { y: 0, opacity: 1 },
                out: { y: -20, opacity: 0 },
              }}
            >
              <Sandbox html={data.text} />
            </motion.div>
            <motion.ul
              tw="flex flex-wrap items-start justify-center w-full mt-4 max-w-screen-lg mx-auto md:mt-8 lg:px-24"
              variants={{
                out: { opacity: 0 },
                in: { opacity: 1, transition: { staggerChildren: 0.1 } },
              }}
            >
              {data.items.map((x) => (
                <motion.li
                  key={x.slug}
                  tw="w-1/2 block mb-2 sm:w-1/3 md:(w-1/5)"
                >
                  <Link
                    to={`${data.prefix || ''}/${x.slug}`}
                    tw="block text-center p-2"
                  >
                    {x.icon && (
                      <Icon
                        name={x.icon}
                        useInView={false}
                        tw="w-12 h-12 mx-auto xl:(w-16 h-16)"
                      />
                    )}
                    <motion.h4
                      css={[
                        tw`text-xs leading-none uppercase text-brand-green font-semibold mt-1`,
                        tw`md:(mt-3 font-bold text-sm leading-none)`,
                        tw`xl:(text-base leading-none)`,
                      ]}
                      variants={{
                        in: { y: 0, opacity: 1 },
                        out: { y: 10, opacity: 0 },
                      }}
                    >
                      {x.name}
                    </motion.h4>
                    <span tw="bg-brand-purple-800 rounded-full text-white inline-block mt-2 md:mt-3">
                      <ArrowRight tw="w-4 h-4" />
                    </span>
                  </Link>
                </motion.li>
              ))}
            </motion.ul>
          </div>
        </Simplebar>
        <button
          tw="absolute top-full left-1/2 w-40 -ml-20 text-center rounded-b-md bg-white"
          onClick={close}
        >
          <ArrowDropUp tw="mx-auto w-6 h-6" />
        </button>
      </motion.div>
    </>
  );
};

export default Submenu;
