export const childVariants = {
  out: { scale: 0 },
  in: {
    scale: 1,
    originX: 0.5,
    originY: 1,
  },
};
export const parentVariants = {
  out: {},
  in: {
    transition: { staggerChildren: 0.3 },
  },
};
