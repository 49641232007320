import React from 'react';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import { childVariants, parentVariants } from './variants';

const Cmp = React.forwardRef(({ useInView, inView, className }, ref) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 390 390"
    ref={ref}
    variants={parentVariants}
    className={className}
    {...(useInView
      ? {
          initial: 'out',
          animate: inView ? 'in' : 'out',
        }
      : {})}
  >
    <motion.g
      variants={{
        out: { scale: 0 },
        in: { scale: 1, originX: 0.5, originY: 0.5, rotate: -360 },
      }}
    >
      <path
        fill="#00A79D"
        d="M205.8 15c6.7.9 13.4 1.8 20.1 2.8 1.3.2 2.5.4 3.8.7 4.4 1 7 4.4 6.2 8.3-.8 3.9-4.4 6-8.8 5.5-11.2-1.2-22.6-3.4-33.8-3-65.8 2.1-115.8 31.9-145.7 90.3C17 179.5 23.4 238.2 61.7 293.4c.7 1 1.4 1.9 1.7 2.3 5.3-1 10.1-2.5 15-2.6 13.2-.3 24.7 8.8 28.2 21.6 4.3 15.7-5 31.9-20.7 36.2-9.8 2.7-20.3.1-27.7-6.8-9.7-8.8-12.3-23-6.3-34.7 1.4-2.7 1.1-4.3-.7-6.7-20.8-27.4-33.2-60.2-35.6-94.4-.1-1-.3-2-.6-3v-21.8c1.1-8 2-16 3.5-23.9C31.9 88 91.6 29.6 163.4 17.8c6.7-1.1 13.4-1.9 20.1-2.8h22.3zM93.5 322.2c-.1-8.5-7-15.4-15.5-15.3-8.5.1-15.4 7-15.3 15.5.1 8.5 7 15.4 15.5 15.3 8.5 0 15.4-6.9 15.3-15.5zM259.1 41.6c-3.9-.2-6.9-3.5-6.8-7.4.2-3.8 3.4-6.7 7.2-6.6 3.8.1 6.9 3.2 6.8 7v.3c-.3 3.7-3.5 6.7-7.2 6.7z"
      />
      <path
        fill="#00A79D"
        d="M183.4 374.4c-6.7-.9-13.4-1.8-20.1-2.8-1.2-.2-2.3-.4-3.4-.7-4.5-1-6.8-4.2-6.1-8.3.7-4.1 4.2-6 8.7-5.5 11.6 1.2 23.2 3 34.9 3 65.3-.3 126.7-42 150.5-102.9 22.4-57.3 15-111.3-20.4-161.6-.5-.7-1-1.3-1.5-2-.2-.2-.3-.3 0 0-5.2 1-10.1 2.5-15 2.6-13.2.3-24.8-8.9-28.2-21.6-4.2-15.7 5.1-31.9 20.8-36.2 9.8-2.6 20.2-.1 27.7 6.8 9.7 8.8 12.2 23 6.3 34.7-1.4 2.7-1.1 4.3.7 6.7 20.8 27.4 33.1 60.2 35.7 94.4.1 1 .3 2.1.6 3.1v21.8c-.2.9-.5 1.8-.6 2.7-1.2 8.3-2 16.7-3.7 24.9-14.4 69.7-74 126.7-144.1 138-6.7 1.1-13.4 1.8-20.1 2.8l-22.7.1zM311 82.4c8.5.1 15.5-6.8 15.6-15.3.1-8.5-6.8-15.5-15.3-15.6-8.5-.1-15.5 6.8-15.6 15.3v.1c0 8.5 6.8 15.4 15.3 15.5zM130.3 361.9c-3.8 0-6.9-3-7-6.8 0-3.9 3.2-7.1 7.2-7.1 3.8.1 6.8 3.3 6.8 7.1 0 3.7-3 6.8-6.7 6.8h-.3z"
      />
    </motion.g>
    <motion.path
      fill="#564879"
      variants={childVariants}
      d="M99.5 253h9.8v9.8h-9.8V253zm19.6 0h9.8v9.8h-9.8V253zm19.6 0h9.8v9.8h-9.8V253zm40.1 0h117.6v9.8H178.8V253zm-79.3 20.3h9.8v9.8h-9.8v-9.8zm19.6 0h9.8v9.8h-9.8v-9.8zm19.6 0h9.8v9.8h-9.8v-9.8zm40.1 0h117.6v9.8H178.8v-9.8zm85.8-85h19.6c0-13.8-3.2-27.5-9.5-39.8l-17 9.8c4.5 9.4 6.9 19.6 6.9 30zM253 217.7l22.8-19.6h-59.6c-2.5 4-4.5 6.9-5.5 8.1-2.6 2.9-6 5-9.8 6-10.5 2.7-21.2-3.6-23.9-14.1h-62.3l19.6 19.6H253zm-135.7-69.2c-6.3 12.3-9.5 26-9.5 39.7h19.6c0-10.3 2.4-20.6 6.9-29.9l-17-9.8zm30.4-34c-10.1 6.7-18.8 15.4-25.5 25.6l17 9.8c4.9-7.3 11.1-13.5 18.3-18.4l-9.8-17zm40.9 72.2c-3.6 4.1-3.2 10.3.9 13.9 4 3.6 10.2 3.2 13.8-.9 2.7-3.1 16.1-25.7 28.4-47-19.6 14.8-40.4 30.9-43.1 34zm2.5-66.8v-19.7c-12.2.7-24.1 3.9-34.9 9.4l9.8 17c7.9-3.8 16.4-6.1 25.1-6.7zm34.8 6.7l9.8-16.9c-10.9-5.6-22.8-8.7-34.9-9.4v19.6c8.7.6 17.2 2.9 25.1 6.7zM177 188.3c.8-3 2.2-5.8 4.3-8.1 4-4.5 31.9-25.9 49.6-39.2-10.1-7.5-22.4-11.5-35-11.5-32.5 0-58.7 26.4-58.7 58.8H177zm45.2 0h32.6c0-12.8-4.2-25.2-11.9-35.4-6.1 10.6-14.1 24.4-20.7 35.4zm47.5-48.3c-6.6-10.1-15.3-18.8-25.5-25.6l-9.8 17c1.6 1.1 3.1 2.2 4.6 3.4 2.9-2.1 5-3.7 5.9-4.4 1.6-1.1 3.7-1.3 5.4-.3 2.3 1.4 3.1 4.3 1.8 6.7-.7 1-2.1 3.5-4 6.9 1.6 2 3.2 4 4.6 6.1l17-9.8zM196 90.3c54 0 98 44 98 98v4.9c0 1.4-.6 2.8-1.7 3.7L258 226.3c-.9.8-2 1.2-3.2 1.2H132.3c-1.3 0-2.6-.5-3.5-1.4l-29.4-29.4c-.9-1-1.4-2.2-1.4-3.5v-4.9c0-54 44-98 98-98zm-39.2 68.6h19.6v9.8h-19.6v-9.8z"
    />
  </motion.svg>
));

const Project = ({ className, useInView = true }) => {
  if (useInView) {
    return (
      <InView threshold={0.5}>
        {({ ref, inView }) => (
          <Cmp
            ref={ref}
            inView={inView}
            useInView={true}
            className={className}
          />
        )}
      </InView>
    );
  }
  return <Cmp className={className} />;
};

export default Project;
