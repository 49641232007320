import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import 'twin.macro';
import Logo from '../svgs/logo';

const Links = ({ items, className }) => (
  <ul
    className={className}
    tw="absolute w-1/2 h-full flex items-center flex-wrap top-0 uppercase text-white text-sm"
  >
    {items.map((item) => (
      <li key={item.slug || item.url}>
        {item.ext ? (
          <a href={item.url} target="_blank" rel="noreferrer">
            {item.label}
          </a>
        ) : (
          <Link to={`/${item.slug}`}>{item.name}</Link>
        )}
      </li>
    ))}
  </ul>
);

const SiteFooter = () => {
  const {
    strapi: { footer },
  } = useStaticQuery(graphql`
    {
      strapi {
        footer {
          pages {
            slug
            name
          }
        }
      }
    }
  `);
  const lhLinks = [
    { label: 'Contact', url: 'mailto:info@smatracker.eu', ext: true },
  ];
  return (
    <div tw="p-6 bg-brand-purple-800 relative xl:p-12" data-site-footer>
      <Links items={lhLinks} tw="left-0 justify-end pr-12 xl:pr-24" />
      <Logo
        tw="w-16 h-16 mx-auto"
        fills={{ background: 'white', foreground: 'white' }}
      />
      <Links items={footer.pages} tw="right-0 justify-start pl-12 xl:pl-24" />
    </div>
  );
};
export default SiteFooter;
