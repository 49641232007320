import { graphql, useStaticQuery, Link } from 'gatsby';
import Logo from '../svgs/logo';
import React, { useState, useEffect } from 'react';
import tw, { styled, css } from 'twin.macro';
import Submenu from './submenu';
import DropdownIcon from '../../images/icons/arrow_drop_down.inline.svg';
import { useContext } from 'react';
import AppContext from './context';
import Headroom from 'react-headroom';
import { motion, AnimatePresence } from 'framer-motion';
import { globalHistory } from '@reach/router';

const MenuList = styled.ul`
  ${tw`flex items-center`}
  a, button {
    ${tw`
      mx-2 h-12 flex items-center text-xs
      relative font-semibold text-brand-purple-800
      uppercase outline-none
    `}
    &:before {
      content: '';
      ${tw`border-t-4 border-transparent absolute top-0 left-0 w-full transition-colors`}
    }
    &.is-active:not(.has-open-menu),
    &.is-open {
      ${tw`text-brand-green`}
      &:before {
        ${tw` border-brand-green`}
      }
    }
    &:hover:not(.is-open):not(.is-active) {
      &:before {
        ${tw`border-brand-purple-400`}
      }
    }
  }
  @media (min-width: 768px) {
    a,
    button {
      ${tw`mx-6 text-base`}
    }
  }
  @media (min-width: 1280px) {
    a,
    button {
      ${tw`mx-10 h-16`}
    }
  }
  @media (min-width: 1440px) {
    a,
    button {
      ${tw`mx-16`}
    }
  }
`;

const ArrowDropDown = tw(
  DropdownIcon
)`w-4 h-4 absolute left-full top-4 xl:(top-6 ml-2)`;

const SiteHeader = () => {
  const [currentSubmenu, setCurrentSubmenu] = useState(null);
  const toggleMenu = (id, title, text, items, prefix) => {
    if (currentSubmenu?.title === title) {
      setCurrentSubmenu(null);
      return;
    }
    setCurrentSubmenu({ id, title, text, items, prefix });
  };
  const {
    strapi: { header },
  } = useStaticQuery(query);
  const { indicators, map } = useContext(AppContext);
  const [headerState, setHeaderState] = useState({
    pinned: false,
    unfixed: true,
  });
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setCurrentSubmenu(null);
    });
  });

  return (
    <Headroom
      style={{ zIndex: 150 }}
      onPin={() => {
        setHeaderState({ pinned: true, unfixed: false });
      }}
      onUnpin={() => {
        setHeaderState({ pinned: false, unfixed: false });
      }}
      onUnfix={() => {
        setHeaderState({ pinned: false, unfixed: true });
      }}
    >
      <div tw="w-full h-12 relative xl:h-16 bg-white" data-site-header>
        <MenuList
          css={[
            tw`absolute right-1/2 pr-4 mr-4`,
            css`
              @media (min-width: 375px) {
                ${tw`mr-8`}
              }
            `,
            tw`sm:(mr-10) md:(mr-12) xl:mr-32 2xl:mr-40`,
          ]}
        >
          <li>
            <Link
              to="/"
              activeClassName="is-active"
              className={currentSubmenu && 'has-open-menu'}
            >
              Home
            </Link>
          </li>
          <li>
            <button
              className={[
                currentSubmenu?.id === 1 && 'is-active',
                'has-dropdown',
              ].join(' ')}
              onClick={() =>
                toggleMenu(
                  1,
                  header.pages_menu_title,
                  header.pages_menu_text,
                  header.pages
                )
              }
            >
              {header.pages_menu_name}
              <ArrowDropDown />
            </button>
          </li>
        </MenuList>
        <Link
          to="/"
          css={[
            tw`absolute block left-1/2 px-2 pb-2 top-2 bg-white -ml-6 rounded-b-sm transition-transform`,
            tw`md:-ml-8`,
            tw`xl:(px-12 pb-4 pt-4 -ml-20 rounded-b-md top-0)`,
            !headerState.pinned &&
              !headerState.unfixed &&
              css`
                transform: translateY(-100%);
              `,
          ]}
          title="Home"
        >
          <Logo
            css={[
              tw`block w-8 h-8 mx-auto mt-2`,
              tw`md:(w-12 h-12 mt-0)`,
              tw`xl:(w-16 h-16)`,
            ]}
          />
        </Link>
        <MenuList
          css={[
            tw`absolute left-1/2 top-0 pl-1 ml-4`,
            css`
              @media (min-width: 375px) {
                ${tw`ml-8`}
              }
            `,
            tw`sm:ml-10 md:(ml-12) xl:ml-32 2xl:ml-40`,
          ]}
        >
          <li tw="mr-1">
            <button
              className={[
                currentSubmenu?.id === 2 && 'is-active',
                'has-dropdown',
              ].join(' ')}
              onClick={() =>
                toggleMenu(
                  2,
                  header.indicators_menu_title,
                  header.indicators_menu_text,
                  indicators,
                  '/indicators'
                )
              }
            >
              {header.indicators_menu_name}
              <ArrowDropDown />
            </button>
          </li>
          <li>
            <Link
              to={`/${map.slug}`}
              activeClassName="is-active"
              className={currentSubmenu && 'has-open-menu'}
            >
              {map.name}
            </Link>
          </li>
        </MenuList>
      </div>
      <AnimatePresence>
        {currentSubmenu && (
          <motion.div initial="out" animate="in" exit="out">
            <Submenu
              data={currentSubmenu}
              key={currentSubmenu?.id}
              close={() => setCurrentSubmenu(null)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Headroom>
  );
};

const query = graphql`
  {
    strapi {
      header {
        pages {
          slug
          name
          icon
        }
        indicators_menu_name
        indicators_menu_title
        indicators_menu_text
        pages_menu_name
        pages_menu_title
        pages_menu_text
      }
    }
  }
`;

export default SiteHeader;
