import React from 'react';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import { childVariants, parentVariants } from './variants';

const Cmp = React.forwardRef(({ useInView, inView, className }, ref) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 360 360"
    ref={ref}
    variants={parentVariants}
    className={className}
    {...(useInView
      ? {
          initial: 'out',
          animate: inView ? 'in' : 'out',
        }
      : {})}
  >
    <motion.path
      variants={{
        out: { scale: 0 },
        in: { scale: 1, originX: 0.5, originY: 0.5, rotate: -360 },
      }}
      fill="#00A79D"
      d="M191.2.3c6.7.9 13.4 1.8 20.1 2.8 1.3.2 2.5.4 3.8.7 4.4 1 7 4.4 6.2 8.3-.8 3.9-4.4 6-8.8 5.5-11.2-1.2-22.6-3.4-33.8-3C112.9 16.7 62.9 46.5 33 104.9 2.4 164.8 8.8 223.5 47.1 278.7c.7 1 1.4 1.9 1.7 2.3 5.3-1 10.1-2.5 15-2.6 13.2-.3 24.7 8.8 28.2 21.6 4.3 15.7-5 31.9-20.7 36.2-9.8 2.7-20.3.1-27.7-6.8-9.7-8.8-12.3-23-6.3-34.7 1.4-2.7 1.1-4.3-.7-6.7C15.8 260.6 3.4 227.8 1 193.6c-.1-1-.3-2-.6-3v-21.8c1.1-8 2-16 3.5-23.9C17.3 73.3 77 14.9 148.8 3.1c6.7-1.1 13.4-1.9 20.1-2.8h22.3zM78.9 307.5c-.1-8.5-7-15.4-15.5-15.3-8.5.1-15.4 7-15.3 15.5.1 8.5 7 15.4 15.5 15.3 8.5 0 15.4-6.9 15.3-15.5zm89.9 52.2c-6.7-.9-13.4-1.8-20.1-2.8-1.2-.2-2.3-.4-3.4-.7-4.5-1-6.8-4.2-6.1-8.3.7-4.1 4.2-6 8.7-5.5 11.6 1.2 23.2 3 34.9 3 65.3-.3 126.7-42 150.5-102.9 22.4-57.3 15-111.3-20.4-161.6-.5-.7-1-1.3-1.5-2-.2-.2-.3-.3 0 0-5.2 1-10.1 2.5-15 2.6-13.2.3-24.8-8.9-28.2-21.6C264 44.2 273.3 28 289 23.7c9.8-2.6 20.2-.1 27.7 6.8 9.7 8.8 12.2 23 6.3 34.7-1.4 2.7-1.1 4.3.7 6.7 20.8 27.4 33.1 60.2 35.7 94.4.1 1 .3 2.1.6 3.1v21.8c-.2.9-.5 1.8-.6 2.7-1.2 8.3-2 16.7-3.7 24.9-14.4 69.7-74 126.7-144.1 138-6.7 1.1-13.4 1.8-20.1 2.8l-22.7.1zm127.6-292c8.5.1 15.5-6.8 15.6-15.3.1-8.5-6.8-15.5-15.3-15.6-8.5-.1-15.5 6.8-15.6 15.3v.1c0 8.5 6.8 15.4 15.3 15.5zm-51.9-40.8c-3.9-.2-6.9-3.5-6.8-7.4.2-3.8 3.4-6.7 7.2-6.6 3.8.1 6.9 3.2 6.8 7v.3c-.3 3.7-3.5 6.7-7.2 6.7zM115.7 347.2c-3.8 0-6.9-3-7-6.8 0-3.9 3.2-7.1 7.2-7.1 3.8.1 6.8 3.3 6.8 7.1 0 3.7-3 6.8-6.7 6.8h-.3z"
    />
    <motion.path
      fill="#564879"
      variants={childVariants}
      d="M180 86.9c-50.8 0-92.1 41.2-92.1 92.1 0 50.9 41.3 92 92.1 92s92.1-41.2 92.1-92.1c0-50.9-41.3-92-92.1-92zm-36.8 142.7c-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23-10.3 23-23 23zm13.8-87.5c0-12.7 10.3-23 23-23s23 10.3 23 23-10.3 23-23 23-23-10.3-23-23zm59.8 87.5c-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23-10.3 23-23 23z"
    />
  </motion.svg>
));

const AboutSMA = ({ className, useInView = true }) => {
  if (useInView) {
    return (
      <InView threshold={0.5}>
        {({ ref, inView }) => (
          <Cmp
            ref={ref}
            inView={inView}
            useInView={true}
            className={className}
          />
        )}
      </InView>
    );
  }
  return <Cmp className={className} />;
};

export default AboutSMA;
