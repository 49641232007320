import React from 'react';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import { childVariants, parentVariants } from './variants';

const Cmp = React.forwardRef(({ useInView, inView, className }, ref) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 384"
    ref={ref}
    variants={parentVariants}
    className={className}
    {...(useInView
      ? {
          initial: 'out',
          animate: inView ? 'in' : 'out',
        }
      : {})}
  >
    <motion.g
      variants={{
        out: { scale: 0 },
        in: { scale: 1, originX: 0.5, originY: 0.5, rotate: -360 },
      }}
    >
      <path
        fill="#00A79D"
        d="M202.8 12c6.7.9 13.4 1.8 20.1 2.8 1.3.2 2.5.4 3.8.7 4.4 1 7 4.4 6.2 8.3-.8 3.9-4.4 6-8.8 5.5-11.2-1.2-22.6-3.4-33.8-3-65.8 2.1-115.8 31.9-145.7 90.3C14 176.5 20.4 235.2 58.7 290.4c.7 1 1.4 1.9 1.7 2.3 5.3-1 10.1-2.5 15-2.6 13.2-.3 24.7 8.8 28.2 21.6 4.3 15.7-5 31.9-20.7 36.2-9.8 2.7-20.3.1-27.7-6.8-9.7-8.8-12.3-23-6.3-34.7 1.4-2.7 1.1-4.3-.7-6.7-20.8-27.4-33.2-60.2-35.6-94.4-.1-1-.3-2-.6-3v-21.8c1.1-8 2-16 3.5-23.9C28.9 85 88.6 26.6 160.4 14.8c6.7-1.1 13.4-1.9 20.1-2.8h22.3zM90.5 319.2c-.1-8.5-7-15.4-15.5-15.3-8.5.1-15.4 7-15.3 15.5.1 8.5 7 15.4 15.5 15.3 8.5 0 15.4-6.9 15.3-15.5zM256.1 38.6c-3.9-.2-6.9-3.5-6.8-7.4.2-3.8 3.4-6.7 7.2-6.6 3.8.1 6.9 3.2 6.8 7v.3c-.3 3.7-3.5 6.7-7.2 6.7z"
      />
      <path
        fill="#00A79D"
        d="M180.4 371.4c-6.7-.9-13.4-1.8-20.1-2.8-1.2-.2-2.3-.4-3.4-.7-4.5-1-6.8-4.2-6.1-8.3.7-4.1 4.2-6 8.7-5.5 11.6 1.2 23.2 3 34.9 3 65.3-.3 126.7-42 150.5-102.9 22.4-57.3 15-111.3-20.4-161.6-.5-.7-1-1.3-1.5-2-.2-.2-.3-.3 0 0-5.2 1-10.1 2.5-15 2.6-13.2.3-24.8-8.9-28.2-21.6-4.2-15.7 5.1-31.9 20.8-36.2 9.8-2.6 20.2-.1 27.7 6.8 9.7 8.8 12.2 23 6.3 34.7-1.4 2.7-1.1 4.3.7 6.7 20.8 27.4 33.1 60.2 35.7 94.4.1 1 .3 2.1.6 3.1v21.8c-.2.9-.5 1.8-.6 2.7-1.2 8.3-2 16.7-3.7 24.9-14.4 69.7-74 126.7-144.1 138-6.7 1.1-13.4 1.8-20.1 2.8l-22.7.1zM308 79.4c8.5.1 15.5-6.8 15.6-15.3.1-8.5-6.8-15.5-15.3-15.6-8.5-.1-15.5 6.8-15.6 15.3v.1c0 8.5 6.8 15.4 15.3 15.5zM127.3 358.9c-3.8 0-6.9-3-7-6.8 0-3.9 3.2-7.1 7.2-7.1 3.8.1 6.8 3.3 6.8 7.1 0 3.7-3 6.8-6.7 6.8h-.3z"
      />
    </motion.g>
    <motion.path
      fill="#564879"
      variants={childVariants}
      d="M146 159.1c9.4 0 18.5 2.5 26.5 7.2 2 18.3 11.7 34.3 25.6 44.9 0 28.7-23.4 52.1-52.1 52.1-2.7 0-5.3-.4-8.5-.9-2.8-.5-5.5.9-6.8 3.4-3.4 6.5-10 10.5-17.2 10.5 2.7-4.9 4.6-10.2 5.6-15.8.5-2.5-.6-5.1-2.7-6.5-14.3-10-22.9-25.9-22.9-42.8 0-28.7 23.5-52.1 52.5-52.1zm91.2-52.1c29 0 52.5 23.4 52.5 52.1 0 16.8-8.6 32.8-22.9 42.8-2.1 1.5-3.2 4-2.7 6.5 1 5.6 2.9 11 5.6 15.8-7.2 0-13.8-4-17.2-10.5-1.3-2.5-4.1-3.9-6.8-3.4-3.2.5-5.9.9-8.5.9-28.7 0-52.1-23.4-52.1-52.1 0-28.7 23.4-52.1 52.1-52.1zM105.4 262c-1.8 6.2-5 11.7-9.5 16.2-1.9 1.9-2.4 4.7-1.4 7.1 1 2.4 3.4 4 6 4h13c10.5 0 20.1-5.1 26.2-13.4 2 .2 4.2.4 6.3.4 33.4 0 61-25.3 64.7-57.7 8.1 3.6 17.1 5.6 26.5 5.6 2.2 0 4.3-.2 6.3-.4 6.1 8.4 15.8 13.4 26.2 13.4h13c2.6 0 5-1.6 6-4s.5-5.2-1.4-7.1c-4.5-4.5-7.8-10.1-9.5-16.2 15.7-12.5 24.9-31.2 24.9-50.8 0-35.9-29.4-65.1-65.6-65.1-33.4 0-61 25.3-64.7 57.7-8.3-3.7-17.3-5.6-26.5-5.6-36.2 0-65.6 29.2-65.6 65.1.1 19.7 9.4 38.4 25.1 50.8z"
    />
    <motion.path
      fill="#564879"
      variants={childVariants}
      d="M224.2 146.1c-3.6 0-6.5-2.9-6.5-6.4 0-10.7 8.7-19.5 19.5-19.5 10.7 0 19.5 8.7 19.5 19.5 0 6.1-3 12-8 15.7-3.2 2.3-5.1 6.1-5.1 10v.3c0 3.6-2.9 6.5-6.5 6.5s-6.5-2.9-6.5-6.5v-.3c0-8.1 3.9-15.7 10.4-20.5 1.7-1.3 2.7-3.2 2.7-5.3 0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5-2.9 6.5-6.5 6.5zm13 32.6c3.6 0 6.5 2.9 6.5 6.5s-2.9 6.5-6.5 6.5-6.5-2.9-6.5-6.5 2.9-6.5 6.5-6.5z"
    />
    <motion.path
      fill="#564879"
      variants={childVariants}
      d="M172.1 217.8c-3.6 0-6.6-3-6.5-6.5 0-3.6 2.9-6.5 6.5-6.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5zm-26.1 0c-3.6 0-6.5-3-6.5-6.5 0-3.6 2.9-6.5 6.5-6.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5zm-26 0c-3.6 0-6.6-3-6.5-6.5 0-3.6 2.9-6.5 6.5-6.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5z"
    />
  </motion.svg>
));

const AboutSMA = ({ className, useInView = true }) => {
  if (useInView) {
    return (
      <InView threshold={0.5}>
        {({ ref, inView }) => (
          <Cmp
            ref={ref}
            inView={inView}
            useInView={true}
            className={className}
          />
        )}
      </InView>
    );
  }
  return <Cmp className={className} />;
};

export default AboutSMA;
