import { useEffect } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const enableScrollLock = (target) => {
  if (!target) return;
  document.documentElement.style.overflow = 'hidden';
  disableBodyScroll(target !== true ? target : null);
};
export const clearScollLock = () => {
  clearAllBodyScrollLocks();
  document.documentElement.style.overflow = null;
};
const useBodySrollLock = (target, clear = true) => {
  useEffect(() => {
    enableScrollLock(target);
    return () => {
      if (clear) {
        clearScollLock();
      }
    };
  }, [target, clear]);
};

export default useBodySrollLock;
