// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-indicator-jsx": () => import("./../../../src/templates/indicator.jsx" /* webpackChunkName: "component---src-templates-indicator-jsx" */),
  "component---src-templates-map-jsx": () => import("./../../../src/templates/map.jsx" /* webpackChunkName: "component---src-templates-map-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

