import _uniq from 'lodash/uniq';
import cookies from 'js-cookie';

const COOKIE_NAME = process.env.GATSBY_GDPR_COOKIE || 'gdpr-cookie';

export const getMessages = () => {
  return (cookies.get(`messages`) || '').split(';');
};
export const setMessage = (uid) => {
  const messages = getMessages();
  cookies.set(
    `messages`,
    _uniq(messages.concat([uid]))
      .filter((x) => Boolean(x))
      .join(';')
  );
};
export const hasMessage = (uid) => {
  return getMessages().includes(uid);
};

export const hasGdprCookie = () => cookies.get(COOKIE_NAME);
export const setGdprCookie = () => cookies.set(COOKIE_NAME, 1);
