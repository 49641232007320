import React from 'react';
import tw, { styled } from 'twin.macro';

const SandboxComponent = styled.div`
  p {
    ${tw`mb-4`};
    &:last-child {
      ${tw`mb-2`}
    }
  }
  strong,
  b {
    ${tw`font-bold`};
  }
  em,
  i {
    ${tw`font-accent`};
  }
  a {
    ${tw`text-brand-green`};
  }
  ul {
    ${tw`my-4`};
    list-style-type: disc;
    padding-left: 16px;

    li {
      ${tw`mb-4`}
    }
  }
  table {
    ${tw`my-6 text-left`}
  }
  thead {
    tr {
      ${tw`border-b border-brand-purple-400`}
    }
    th {
      ${tw`text-brand-purple-600`}
    }
  }
  tbody {
    tr {
      ${tw`border-b border-gray-200`}
    }
    td {
      ${tw`text-base py-4 pr-2 text-gray-600 leading-tight`}
    }
    tr:first-of-type > td {
      ${tw`pt-6`}
    }
  }
  h4 {
    ${tw`text-lg font-bold`};
  }

  h3 {
    ${tw`text-brand-green text-2xl mt-6 mb-4`}
  }

  .text-columns--2,
  .text-columns--3 {
    column-width: 280px;
    column-fill: balance;
    column-gap: 2em;
    ${tw`mb-4`}
  }
  .text-columns--2 {
    column-count: 2;
  }
  .text-columns--3 {
    column-count: 3;
  }
`;

const Sandbox = ({ html, className }) => (
  <SandboxComponent
    dangerouslySetInnerHTML={{ __html: html }}
    className={className}
  />
);

export default Sandbox;
