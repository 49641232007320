import React from 'react';
import { Global, css } from '@emotion/react';
import tw, { theme, GlobalStyles as BaseStyles } from 'twin.macro';

const customStyles = css`
  @import url('https://use.typekit.net/cxt8avo.css');
  body {
    -webkit-tap-highlight-color: ${theme`colors.brand.purple.400`};
    ${tw`antialiased font-body w-full`}
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
);

export default GlobalStyles;
